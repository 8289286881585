import React from 'react';
import Layout from '../components/Layout';
import SEO from "../components/SEO"
import { graphql } from 'gatsby'


const About = (props) => {
  const frontmatter = {
    slug: props.location.pathname.replace(/[^\w\d-]/g, '')
  };

  return (
    <Layout location={props.location}>
       <SEO
        title="About Heat Pump DIY Project"
        description="I thank our government for raising the gas prices, which leads to people like me taking interest in projects like that. Now you can build your own heat pump and save a lot of money."
        frontmatter={frontmatter}
        slug='/about/'
      />
      <h1>About</h1>
      <p>DIY Heat Pump project demonstrates how you can switch to heating with electricity at a minimal cost. </p>
      <p>The goal was to show how to do it yourself and don't make the same mistakes that I did.</p>
     
    </Layout>
  );
}

export default About;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`